<template>
  <div class="content">
    <ApplicationNannyInputSchedule
      v-model:schedule-value="applicationNanny.schedule.value"
      v-model:schedule-comment="applicationNanny.schedule.comment"
      v-model:schedule-date-start="applicationNanny.schedule.dateStart"
      @update:is-valid="isValid = $event"
    />

    <ButtonPrimaryNext
      :to="{ name: 'ApplicationNannyCreateSalary' }"
      :isDisabled="!isValid"
    />
  </div>
</template>
<script>
import { ref } from "@vue/reactivity";
import { useApplicationNannyStore } from "@/stores/applicationNanny";

import ApplicationNannyInputSchedule from "@/components/Application/Nanny/ApplicationNannyInputSchedule.vue";
import ButtonPrimaryNext from "@/components/UI/ButtonPrimaryNext.vue";

export default {
  components: {
    ButtonPrimaryNext,
    ApplicationNannyInputSchedule,
  },

  setup() {
    const applicationNanny = useApplicationNannyStore();

    const isValid = ref(false);

    return {
      applicationNanny,
      isValid,
    };
  },
};
</script>

<style scoped>
.content {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 60px;
}

@media (max-width: 1200px) {
}
</style>
